/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faFileLines, faBell, faMessage, faTableCellsLarge, faMagnifyingGlass, faExclamationTriangle, faPenToSquare, faXmark, faBars, faHeart } from '@fortawesome/free-solid-svg-icons'

import { faFolderOpen, faUser, faFile, faMessage as faMessageRegular } from '@fortawesome/free-regular-svg-icons'

library.add(faFileLines, faBell, faMessage, faTableCellsLarge, faMagnifyingGlass, faFolderOpen, faExclamationTriangle, faUser, faFile, faPenToSquare, faXmark, faMessageRegular, faBars, faHeart)

export default FontAwesomeIcon