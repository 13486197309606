export enum ProposalStatus {
    g_lst_prop_status_sent = 'g_lst_prop_status_sent',
    g_lst_prop_status_refused = 'g_lst_prop_status_refused',
    g_lst_prop_status_signed = 'g_lst_prop_status_signed',
    g_lst_prop_status_revised = 'g_lst_prop_status_revised',
    g_lst_prop_status_expired = 'g_lst_prop_status_expired'
}

export enum EmailVerifiedStatus {
    g_lst_account_email_status_verified = 'g_lst_account_email_status_verified',
    g_lst_account_email_status_notverified = 'g_lst_account_email_status_notverified',
    g_lst_account_email_status_invalid = 'g_lst_account_email_status_invalid',
    g_lst_account_email_status_inaccurate = 'g_lst_account_email_status_inaccurate',
}

export enum Gender {
    g_lst_gender_mister = 'g_lst_gender_mister',
    g_lst_gender_madam = 'g_lst_gender_madam',
    g_lst_gender_miss = 'g_lst_gender_miss',
    g_lst_gender_undef = 'g_lst_gender_undef',
}

export enum GeneralStatus {
    g_lst_gen_status_pending = 'g_lst_gen_status_pending',
    g_lst_gen_status_active = 'g_lst_gen_status_active',
    g_lst_gen_status_inactive = 'g_lst_gen_status_inactive',
    g_lst_gen_status_archived = 'g_lst_gen_status_archived',
    gen_status_erased = 'gen_status_erased'
}

export enum ConstructWorkUnitType {
    g_lst_wuType_gu = "g_lst_wuType_gu",
    g_lst_wuType_as = "g_lst_wuType_as",
    g_lst_wuType_sm = "g_lst_wuType_sm",
    g_lst_wuType_lm = "g_lst_wuType_lm",
    g_lst_wuType_mh = "g_lst_wuType_mh",
    g_lst_wuType_md = "g_lst_wuType_md",
    g_lst_wuType_lt = "g_lst_wuType_lt",
    g_lst_wuType_kg = "g_lst_wuType_kg",
    g_lst_wuType_cm = "g_lst_wuType_cm"
}

export enum WorkStatus {
    g_lst_work_status_pending = "g_lst_work_status_pending",
    g_lst_work_status_ongoing = "g_lst_work_status_ongoing",
    g_lst_work_status_terminated = "g_lst_work_status_terminated",
    g_lst_work_status_cancelled = "g_lst_work_status_cancelled"
}

export enum WorkEventType {
    wevent_type_lifecycle = "wevent_type_lifecycle",
    wevent_type_date = "wevent_type_date",
    wevent_type_pay = "wevent_type_pay",
    wevent_type_update = "wevent_type_update",
    wevent_type_other = "wevent_type_other",
    wevent_type_danger = "wevent_type_danger",
    wevent_type_alert = "wevent_type_alert",
    wevent_type_image = "wevent_type_image"
}

export enum AccountType {
    g_txt_client = "g_txt_client",
    g_txt_pro = "g_txt_pro"
}

export enum ErrorResponse {
    USER_NOT_FOUND = "user_not_found"
}

export enum InvoiceNature {
    g_lst_invoice_nature_deposit = "g_lst_invoice_nature_deposit",
    g_lst_invoice_nature_full = "g_lst_invoice_nature_full",
    g_lst_invoice_nature_balance = "g_lst_invoice_nature_balance"
}

export enum InvoiceStatus {
    g_lst_invoice_status_sent = "g_lst_invoice_status_sent",
    g_lst_invoice_status_paid = "g_lst_invoice_status_paid"
}

export enum CreditenoteStatus {
    g_lst_creditnote_status_sent = "g_lst_creditnote_status_sent"
}

export enum BusinessRequestStatus {
    g_lst_gen_status_pending = "g_lst_gen_status_pending",
    g_lst_gen_status_active = "g_lst_gen_status_active",
    g_lst_gen_status_inactive = "g_lst_gen_status_inactive",
    g_lst_gen_status_new = "g_lst_gen_status_new",
}